body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f8!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f6f6f8;
  width: 100%;
}

input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  margin: 10px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.modalHead {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.modalMid {
  display: flex;
}

.modalMid div {
  margin-right: auto;
  padding-bottom: 10px;
}

.modalFooter {
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
}

.modalFooter button {
  background-color: #454545;
} 

.modalMid button:hover {
  background-color: #454545;
}

.modalFooter button:hover {
  background-color: #eb4034;
}

.cadastroBox {
  width: 100%;
  padding: 1em;
  align-items: flex-start;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
  height: auto;
}

.modalMid-form {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cadastroFooter {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cadastroFooter button {
  margin-top: 1em;
  margin-right: 1em;
  background-color: #454545;
} 

.cadastroFooter button:hover {
  background-color: #eb4034;
}

.table {
  width: 100%;
}

.relatorio {
  width: 100%;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
  height: auto;
}

.menuLateral {
  width: 20%;
  margin-right: 1em;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
  height: auto;
  display: flex;
}

.menu-item {
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.pageBox {
  display: flex;
  width: 100%;
  justify-content: stretch;
  align-items: flex-start;
}

.botoesLista {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.botoesLista button {
  width: 48%;
  background-color: #454545;
  font-size: 14;
}

.botoesLista  button:hover {
  background-color: #eb4034;
}

.buttonArquivo button {
  margin-right: 1em;
  background-color: #454545; 
}

.buttonArquivo button:hover {
  background-color: #eb4034;
}

.avaliacaoImport {
  display: flex;
  align-items: center;
}

.avaliacaoImport div {
  margin-right: 1em;
}

.tabComponent {
  margin-right: 10px!important;
  align-items: flex-start!important;
  padding: 12px 0!important;
}

.p-multiselect-panel {
  z-index: 9999!important;
}

.p-multiselect-error {
  border: 1px solid #d32f2f!important;
}

.w-full {
  width: 100%;
}