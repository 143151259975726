.headLogoImg {
    margin-right: auto;
    margin-left: 1em;
}

.headLogoImg:hover {
    cursor: pointer;
}

.sideInfos {
    text-align: center;
    background-color: #fff;
}

.MuiDrawer-paper {
    background-color: #454545!important;
    border: none!important;
    overflow: hidden!important;
}

.MuiDrawer-paper {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


.MuiDrawer-paper::-webkit-scrollbar {
    display: none;
}